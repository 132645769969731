.login-container .MuiFormControl-marginDense {
  width: 100%;
}
.logotypeText{
  color: white;
  font-weight: 500;
  font-Size: 50px;
  margin-block-start: 0em;
  margin-block-end: 0em;
}
.login-container .makeStyles-logotypeImage-3{
  margin-bottom: 0px;
}

.Login-blk .MuiTab-wrapper{
  font-weight: 500;
}
.forgetButton{
  text-transform: capitalize !important;
  padding-left: 15px;
  text-align: right;
  width: 100%;
  text-decoration: none;
}
.pt-15{
  padding-top: 15px;
}

.Login-blk {
  text-align: center;  
  padding: 0px 15px;  
}

.Login-section {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
   }
.login-container{
  margin-left: auto !important;
  margin-right: auto !important;
}

.Login {
  display: flex;
  flex-direction: column;
  justify-content: center;    
}

.Button {
  padding: 15px 0px;
  font-size: 12px !important;
}
.Login-section .MuiButton-containedPrimary,.Login-section .MuiFormLabel-root{
font-size: 12px !important;
}

.login-heading{
  text-transform: uppercase;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  color: #3f51b5;
  font-weight: 600;
  letter-spacing: 5px;
  font-size: 16px;
}
.forget-link{
  font-size: 12px;
  text-align: end;
  color: #999999c4;
  text-decoration: none;
  cursor: pointer;
}
.forget-link:hover,.signup:hover{
    text-decoration: underline;
}
.signup-link{
  font-size: 12px;    
  color: #999999c4;
  text-decoration: none;
  padding-top: 10px;
}
.signup-link span{
    padding-right: 5px;
}
.signup{
    color:#3f51b5;
}
.ptb-50{
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}
.rad-15{
  border-radius: 15px !important;
}

.field-grp .MuiFormControl-marginDense{
width: 100%;
}
.field-grp{
padding-top: 15px;
}

.logo{
width: 80px;
  height: auto;
}
.wd-60{
width: 60%;
background: cornflowerblue;
color: #fff;
text-align: center;
}
.wd-40{
width: 40%;
}
.main-heading{
letter-spacing: 5px;
text-transform: uppercase;
}
.flex-sec-login{
  display: flex;
}
@media(min-width:1280px){
  .Login-section .max-50{
      width: 50%;
      margin-left: auto;
      margin-right: auto;
  }
}
@media(max-width:1279px) and (min-width:992px){
  .Login-section .max-50{
      width: 75%;
      margin-left: auto;
      margin-right: auto;
      padding: 0 15px;
  }
}
@media(max-width:991px){
  .Login-section .max-50{
      width: 100%;       
      padding: 0 15px;
  }
}

.subGreeting{
  text-align: center;
  font-weight: 600 !important;
  font-size: 16px !important;
  padding-top: 15px;
  letter-spacing: 1px !important;
  text-transform: uppercase;
  color: #7d7d7d;
}
.PrivateTabIndicator-colorPrimary-2 {
  background-color: #3f51b5;
  display: none;
}
.active .Mui-selected {
  color: #3f51b5;
  border-bottom: 2px solid #3f51b5;
}
.active{
  min-height: 50px !important;
}
@media (min-width: 600px){
  .MuiTab-root {
    min-width: 100px !important;
}
}

.copyright-section{
  padding: 10px 0px;
    /* margin: 10px 0px; */
    /* border-top: 1px solid #f2f2f2; */
} 
.copyright{
  font-size: 14px !important;
}
.Login-blk .google-login{
  color: #333 !important;
    box-shadow: 0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A;
    margin-top: 15px;
    margin-bottom: 15px;
    text-transform: none;
    background-color: white;
    font-size: 14px;
    padding: 15px 20px;
    font-weight: 600;
}